import React, { ChangeEvent, FC, useCallback } from "react"
import { FormGroup, Switch } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid"

import { useLocalizationContext } from "@context"
import { SFormControlLabel } from "./_styles"
import { IRecJournalAPI } from "../../_useRecJournalApi"

interface ISwitchingStatus {
  params: GridRenderCellParams
  patchRecommendation: IRecJournalAPI["patchRecommendation"]
}

/**
 * Переключатель статуса рекомендации (Новое/Просмотрено)
 *
 * @param {boolean} params - Свойства отдельной рекомендации
 * @param {IRecJournalAPI["patchRecommendation"]} patchRecommendation - PATCH-запрос
 *
 * @returns {FC<ISwitchingStatus>} - FC<IRecommendationJournal>
 */
export const SwitchingStatus: FC<ISwitchingStatus> = ({
  params: {
    row: { id, visible, protocol },
  },
  patchRecommendation,
}) => {
  const {
    l: {
      recommendation: { newRecommendation, viewed },
    },
  } = useLocalizationContext()

  const handleChangeStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      patchRecommendation(protocol)({
        params: { id },
        payload: { visible: event.target.checked },
        onSuccess: () => {},
      })
    },
    [protocol]
  )

  return (
    <FormGroup>
      <SFormControlLabel
        control={<Switch onChange={(event): void => handleChangeStatus(event)} checked={visible} />}
        label={visible ? newRecommendation : viewed}
      />
    </FormGroup>
  )
}
