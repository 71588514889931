import { useCallback, useState, useMemo, useEffect, useRef } from "react"
import {
  EProtocol,
  EWSMessageTypes,
  EchoWSController,
  IRequestWrapperArgs,
  TUseDataGridMethods,
  useDataGrid,
} from "shared"

import { recsAPI } from "@api"
import {
  IEchoRecommendation,
  IModbusRecommendation,
  IOPCRecommendation,
  IRecommendation,
  IRecommendationList,
} from "@types"

interface IUseRecJournalAPI {
  ws: EchoWSController
  equipmentId?: number
  isDisplayed?: boolean
}

interface IIsValidMessageArgs {
  equipmentId?: number
  newRec: IRecommendation
  recommendationList: IRecommendationList
  dataGridMethods: TUseDataGridMethods
}

interface IHasVisibleChangedArgs {
  isRecUpdate: boolean
  recommendationList: IRecommendationList
  newRec: IRecommendation
}

export interface IRecJournalAPI {
  recommendationList: IRecommendationList
  getRecommendationList(params?: string): void
  dataGridMethods: TUseDataGridMethods
  isPending: boolean
  patchRecommendation: (
    protocol: EProtocol
  ) => (args: IRequestWrapperArgs<IEchoRecommendation | IModbusRecommendation | IOPCRecommendation>) => Promise<void>
}

export const useRecJournalAPI = ({ ws, equipmentId, isDisplayed = true }: IUseRecJournalAPI): IRecJournalAPI => {
  const [isPending, setIsPending] = useState(true)
  const [recommendationList, setRecommendationList] = useState<IRecommendationList>({ results: [], count: 0 })

  const getRecommendationList = useCallback((params?: string): void => {
    recsAPI.getRecList({
      params: { reqString: params },
      onSuccess: (data) => {
        setRecommendationList(data)
      },
      onPending: (_isPending) => {
        setIsPending(_isPending)
      },
      onError: () => {
        console.log("Ошибка скрытия рекомендации")
      },
    })
  }, [])

  const dataGridMethods = useDataGrid(getRecommendationList, {
    customDefaultValues: { equipment: equipmentId?.toString() },
    customFieldNames: { eventType: "type" },
    includeDateRange: true,
  })

  const patchRecommendation: IRecJournalAPI["patchRecommendation"] = (protocol: EProtocol) => {
    const { patchEchoRec, patchModbusRec, patchOPCRec } = recsAPI

    if (protocol === EProtocol.ECHO) return patchEchoRec

    return protocol === EProtocol.OPC ? patchOPCRec : patchModbusRec
  }

  const recRef = useRef({ equipmentId, recommendationList, dataGridMethods, isDisplayed })

  const observerObject = useMemo(
    () => ({
      cb: (data: IRecommendation): void => {
        if (!recRef.current.isDisplayed) return

        if (!isValidMessage({ ...recRef.current, newRec: data })) return

        getRecommendationList(recRef.current.dataGridMethods.queryString)
      },
      type: [
        EWSMessageTypes.MODULE_RECOMMENDATION,
        EWSMessageTypes.EXTERNAL_MODBUS_RECOMMENDATION,
        EWSMessageTypes.EXTERNAL_OPC_RECOMMENDATION,
      ],
    }),
    []
  )

  useEffect(() => {
    ws.on(observerObject)

    return () => {
      ws.off(observerObject)
    }
  }, [])

  useEffect(() => {
    recRef.current = { equipmentId, recommendationList, dataGridMethods, isDisplayed }
  }, [equipmentId, recommendationList, dataGridMethods, isDisplayed])

  return { recommendationList, getRecommendationList, isPending, dataGridMethods, patchRecommendation }
}

function isValidMessage({ equipmentId, newRec, recommendationList, dataGridMethods }: IIsValidMessageArgs): boolean {
  if (equipmentId !== undefined && newRec.equipment !== equipmentId) return false

  const isRecUpdate = recommendationList.results.map((item) => item.id).includes(newRec.id)

  if (dataGridMethods.values.page === 1 && !isRecUpdate) return true

  return hasVisibleChanged({ isRecUpdate, recommendationList, newRec })
}

function hasVisibleChanged({ isRecUpdate, recommendationList, newRec }: IHasVisibleChangedArgs): boolean {
  if (!isRecUpdate) return false

  const targetRec = recommendationList.results.find((rec) => rec.id === newRec.id)!

  return targetRec.visible !== newRec.visible
}
