import React, { FC } from "react"
import { GridRenderCellParams } from "@mui/x-data-grid"
import { Tooltip } from "@mui/material"

import { ERecommendationType } from "@types"
import { STooltipText } from "./_styles"

interface ICellTooltip {
  params: GridRenderCellParams
}

/**
 * Фрагмент "Tooltip ячейки таблицы"
 *
 * @param {GridRenderCellParams} params - Свойства отдельной ячейки
 *
 * @returns {<ICellTooltip>} - FC<ICellTooltip>
 */
export const CellTooltip: FC<ICellTooltip> = ({ params }) => {
  const {
    value,
    row: { type, description, name },
  } = params

  return (
    <Tooltip title={value} disableHoverListener={true}>
      <STooltipText children={type === ERecommendationType.RECOMMENDATION ? description : name} />
    </Tooltip>
  )
}
