import React from "react"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { dateTimeFormatter, ERequestStatus, ILocale } from "shared"

import { SwitchingStatus, TypeRecommendation, CellTooltip, DetailsButton } from "./fragments"
import { IRecJournalAPI } from "./_useRecJournalApi"

interface IRecJournalColumnsArgs {
  patchRecommendation: IRecJournalAPI["patchRecommendation"]
  enableDetails: boolean
  handleDetailsClick: (id: number) => void
  reqStatusObj: Record<number, ERequestStatus>
  l: ILocale
}

/**
 * Данные для заголовков ячеек таблицы "Журнал рекомендаций"
 */
export const getRecommendationJournalColumns = (args: IRecJournalColumnsArgs): GridColDef[] => {
  const { patchRecommendation, enableDetails, handleDetailsClick, reqStatusObj, l } = args

  const columns = [
    { field: "id", hide: true, type: "number" },
    {
      field: "time",
      headerName: l.downloads.date,
      type: "string",
      width: 160,
      renderCell: (params: GridRenderCellParams): string => dateTimeFormatter(params.value),
    },
    {
      field: "equipment_name",
      headerName: l.equipment.equipment,
      type: "string",
      flex: 0.5,
    },
    {
      field: "item_name",
      headerName: l.ui.source,
      type: "string",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: l.ui.description,
      type: "string",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams): JSX.Element => <CellTooltip params={params} />,
    },
    {
      field: "type",
      headerName: l.ui.type,
      type: "string",
      width: 165,
      renderCell: (params: GridRenderCellParams): JSX.Element => <TypeRecommendation params={params} />,
    },
    {
      field: "period",
      headerName: l.ui.period,
      type: "string",
      width: 300,
      renderCell: (params: GridRenderCellParams): string =>
        `${dateTimeFormatter(params.value.lower)} - ${dateTimeFormatter(params.value.upper)}`,
    },
    {
      field: "visible",
      headerName: l.ui.status,
      type: "string",
      width: 180,
      renderCell: (params: GridRenderCellParams): JSX.Element => (
        <SwitchingStatus patchRecommendation={patchRecommendation} params={params} />
      ),
    },
  ]

  if (enableDetails) {
    columns.push({
      field: "details",
      headerName: "",
      type: "string",
      width: 180,
      renderCell: ({ row }: GridRenderCellParams): JSX.Element => (
        <DetailsButton {...row} onClick={handleDetailsClick} l={l} reqStatusObj={reqStatusObj} />
      ),
    })
  }

  return columns
}
