import { FormControlLabel, styled } from "@mui/material"

export const SFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
  // "& .MuiFormControlLabel-label .Mui-disabled": {
  //   color: `${theme.palette.common.white} !import`,
  // },
}))
