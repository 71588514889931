import React, { FC } from "react"
import { Stack } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid"
import { WarningIcon } from "shared"

import { useLocalizationContext } from "@context"
import { ERecommendationType } from "@types"

interface ITypeRecommendation {
  params: GridRenderCellParams
}

/**
 * Тип рекомендации (Рекомендация/Уставки)
 *
 * @param {GridRenderCellParams} params - Свойства отдельной рекомендации
 *
 * @returns {FC<ISwitchingStatus>} - FC<IRecommendationJournal>
 */
export const TypeRecommendation: FC<ITypeRecommendation> = ({ params }) => {
  const {
    l: {
      recommendation: { recommendation, thresholds, fault },
    },
  } = useLocalizationContext()

  const message = ((): string => {
    switch (params.row.type) {
      case ERecommendationType.RECOMMENDATION:
        return recommendation
      case ERecommendationType.SETPOINTS:
        return thresholds
      case ERecommendationType.FAULT:
        return fault
      default:
        return ""
    }
  })()

  return (
    <Stack flexDirection="row" gap={2}>
      <WarningIcon width={20} height={20} visible={params.row.visible} />
      {message}
    </Stack>
  )
}
