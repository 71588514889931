import React from "react"
import { GridSelectionModel } from "@mui/x-data-grid"
import { excludeColumns, DataGrid, TSetState } from "shared"

import { useLocalizationContext } from "@context"
import { getRecommendationJournalColumns } from "./_columns"
import { IRecJournalAPI } from "./_useRecJournalApi"
import { HeuristicAnalysisModal, useHVAModal } from "../heuristic_analysis_modal"

interface IRecommendationJournal {
  recJournalAPI: IRecJournalAPI
  selectionRows?: GridSelectionModel
  setSelectionRows?: TSetState<GridSelectionModel>
  excludeColumnList?: string[]
  height?: number | string
  showCheckboxSelection?: boolean
  enableDetails?: boolean
}

/**
 * Таблица "Журнал рекомендаций"
 *
 *
 * @param {IRecJournalAPI} recJournalAPI - Апи журнала
 * @param {GridSelectionModel} selectionRows - Массив id выбранных строк
 * @param {TSetState<GridSelectionModel>} setSelectionRows - Функция/сеттер для выбора строк в таблице
 * @param {string[]} excludeColumnList - Принимает массив столбцов для исключения из "Журнал рекомендаций"
 * @param {number} height - Задает высоту таблицы
 * @param {boolean} hideCheckboxSelection - Скрыть чекбоксы у строк таблицы
 * @param {boolean} enableDetails - Отображать кнопку "Подробнее"
 *
 * @returns {React.FC<IRecommendationJournal>} - FC<IRecommendationJournal>
 */
export const RecommendationJournal: React.FC<IRecommendationJournal> = ({
  recJournalAPI,
  selectionRows,
  setSelectionRows,
  excludeColumnList,
  showCheckboxSelection = true,
  height,
  enableDetails = true,
}) => {
  const { l } = useLocalizationContext()

  const { recommendationList, dataGridMethods, isPending, patchRecommendation } = recJournalAPI

  const { handleDetailsClick, HVAData, isModalOpen, toggleModal, reqStatusObj } = useHVAModal()

  const recommendationJournalColumns = getRecommendationJournalColumns({
    patchRecommendation,
    handleDetailsClick,
    enableDetails,
    reqStatusObj,
    l,
  })

  return (
    <>
      <DataGrid
        columns={
          excludeColumnList
            ? excludeColumns(recommendationJournalColumns, excludeColumnList)
            : recommendationJournalColumns
        }
        rows={recommendationList.results}
        rowCount={recommendationList.count}
        paginationMethods={dataGridMethods.setValues}
        externalPageState={dataGridMethods.values.page - 1}
        externalPageSizeLimitState={dataGridMethods.values.limit}
        selectionRows={selectionRows}
        setSelectionRows={setSelectionRows}
        checkboxSelection={showCheckboxSelection}
        loading={isPending}
        rowHeight={38}
        height={height}
        l={l}
      />

      {isModalOpen && <HeuristicAnalysisModal HVAData={HVAData} setIsOpenModal={toggleModal} />}
    </>
  )
}
