import React from "react"
import { Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material"

import { ERecommendationType, IRecommendation } from "@types"
import { LoadingButton } from "@mui/lab"
import { ERequestStatus, ILocale } from "shared"

interface IDetailsButton extends Pick<IRecommendation, "id" | "type"> {
  l: ILocale
  reqStatusObj: Record<number, ERequestStatus>
  onClick: (id: number) => void
}

export const DetailsButton: React.FC<IDetailsButton> = ({ type, id, l, reqStatusObj, onClick }) => {
  const {
    more,
    hva: {
      detailsMessages: { wrongType, noData, error },
    },
  } = l.recommendation

  const isWrongType = type !== ERecommendationType.FAULT
  const isError = checkStatus(reqStatusObj[+id], ERequestStatus.ERROR)
  const isEmpty = checkStatus(reqStatusObj[+id], ERequestStatus.EMPTY)
  const isPending = checkStatus(reqStatusObj[+id], ERequestStatus.PENDING)

  const isDisabled = isError || isEmpty

  const tooltipTitle = ((): string => {
    if (isWrongType) return wrongType
    if (isEmpty) return noData
    if (isError) return error

    return ""
  })()

  return (
    <STooltip
      placement="bottom"
      arrow
      leaveDelay={500}
      disableHoverListener={(!isWrongType && !isDisabled) || !tooltipTitle}
      title={<Typography variant="body3">{tooltipTitle}</Typography>}
      children={
        <span>
          <SButton
            loading={isPending}
            variant="outlined"
            color="secondary"
            disabled={isWrongType || isDisabled}
            onClick={(): void => onClick(+id)}
            children={<Typography variant="caption" children={more} />}
          />
        </span>
      }
    />
  )
}

const SButton = styled(LoadingButton)({
  height: "24px",
})

const STooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
})

const checkStatus = <T extends ERequestStatus>(status: T | undefined, statusToMatch: T | T[]): boolean => {
  if (!status) return false

  if (Array.isArray(statusToMatch)) {
    return statusToMatch.includes(status)
  }

  return status === statusToMatch
}
